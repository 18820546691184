import styled from '@emotion/styled';
import { PageProps } from 'gatsby';
import React, { FC, SyntheticEvent, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import CMSView from '~/components/View';
import IconArrowUp from '~/icons/ArrowUp';
import IconCamera from '~/icons/Camera';
import IconCircleStop from '~/icons/CircleStop';
import IconMicrophone from '~/icons/Microphone';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';
import useMediaRecorder from '~/utils/useMediaRecorder';

const Textarea = styled.textarea`
  resize: none;
  outline: none;
  height: 24px;
  line-height: 24px;
`.withComponent(TextareaAutosize);

const ImportPage: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
}) => {
  const [text, setText] = useState<string>('');
  const { error, status, start, stop, url } = useMediaRecorder();
  const [hasVideo, setHasVideo] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);

  const handleOnChange = (event: SyntheticEvent<any>) => {
    const { value } = event.currentTarget;

    setText(value);
  };

  const handleOnCreate = async (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    // const model = new CommentData({
    //   espaceId: espace.id,
    //   parentCollectionName: collectionName,
    //   parentId: docId,
    // });
    //
    try {
      //   setText('');
      //   await toast.promise(
      //     model.create({
      //       text,
      //     }),
      //     {
      //       error: "Une erreur c'est produit lors de la création",
      //       pending: "En cours de création d'un commentaire",
      //       success: 'La création du commentaire a réussi',
      //     },
      //   );
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  const handleStartAudioRecording = () => {
    setHasVideo(false);
    setIsRecording(true);
    start({ audio: true });
  };

  const handleStartVideoRecording = () => {
    console.info(
      "navigator.userAgent.includes('iPhone'",
      navigator.userAgent.includes('iPhone'),
    );
    if (!navigator.userAgent.includes('iPhone')) {
      setHasVideo(true);
      setIsRecording(true);
      start({ audio: true, video: true });
    } else {
      const file = document.querySelector(
        '#input-file__image',
      ) as HTMLInputElement;
      if (file) {
        file.click();
      }
    }
  };

  const handleStopRecording = () => {
    setIsRecording(false);
    stop();
  };

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <div className="relative">
          {hasVideo && isRecording && (
            <video autoPlay id="video--preview" muted playsInline />
          )}
          {!hasVideo && 'stopped' === status && url && (
            <audio controls src={url} />
          )}

          {hasVideo && 'stopped' === status && url && (
            <video controls src={url} />
          )}

          {error && <span className="text-danger-500">{error}</span>}

          <div className="hidden">
            <input
              accept="image/*;capture=camera"
              id="input-file__image"
              type="file"
            />
          </div>

          <div
            className="fixed bottom-[92px] md:bottom-[24px] left-0 md:left-[207px] right-0 md:right-[50px]"
            id="messages-box"
          >
            <div className="flex space-x-2 mx-3 md:mx-6 items-end">
              <Button
                className="h-[36px] w-[36px] text-neutral-400"
                iconClassName="h-[24px]"
                iconLeft={
                  'recording' === status && hasVideo
                    ? IconCircleStop
                    : IconCamera
                }
                onClick={
                  'recording' === status && hasVideo
                    ? handleStopRecording
                    : handleStartVideoRecording
                }
                variant="clear"
              />
              <div className="flex flex-1 rounded-[18px] items-end pl-[12px] border bg-light-100">
                <Textarea
                  className="rounded-r-none flex-1 bg-transparent my-[6px] items-center"
                  maxRows={text ? undefined : 1}
                  onChange={handleOnChange}
                  placeholder="Message"
                  value={text}
                />
                <div>
                  {text ? (
                    <Button
                      className="rounded-full m-[4px] h-[28px] w-[28px]"
                      color="neutral"
                      iconClassName="h-[14px] w-[14px]"
                      iconLeft={IconArrowUp}
                      onClick={handleOnCreate}
                      size="xs"
                      square
                      title="Enregistrer un message"
                    />
                  ) : (
                    <Button
                      className="rounded-full m-[4px] h-[28px] w-[28px] p-0 border-none bg-transparent text-neutral-400"
                      color="neutral"
                      iconClassName="h-[18px] w-[18px]"
                      iconLeft={
                        'recording' === status && !hasVideo
                          ? IconCircleStop
                          : IconMicrophone
                      }
                      onClick={
                        'recording' === status && !hasVideo
                          ? handleStopRecording
                          : handleStartAudioRecording
                      }
                      size="xs"
                      square
                      title="Enregistrer un audio"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(ImportPage);
